import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Query Data",
  "includeToc": false,
  "permalink": "/query-data/"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const DataFilterProviderWrapper = makeShortcode("DataFilterProviderWrapper");
const Box = makeShortcode("Box");
const Grid = makeShortcode("Grid");
const QueryTableToolbar = makeShortcode("QueryTableToolbar");
const QueryToolTable = makeShortcode("QueryToolTable");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <DataFilterProviderWrapper defaults='query_table_filter_default' mdxType="DataFilterProviderWrapper">
      <Box mb={4} mdxType="Box">
        <Grid container spacing={2} justify="flex-end" mdxType="Grid">
  <Grid item xs={12} mdxType="Grid">
    <QueryTableToolbar label='Query data' mdxType="QueryTableToolbar" />
  </Grid>
  <Grid item xs={12} mdxType="Grid">
    <QueryToolTable mdxType="QueryToolTable" />
  </Grid>
        </Grid>
      </Box>
    </DataFilterProviderWrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      